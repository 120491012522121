@font-face {
  font-family: "Constan";
  src: url("./fonts/constan.ttf") format("truetype");
}
@font-face {
  font-family: "Cangste";
  src: url("./fonts/Cangste.otf") format("opentype");
}
@font-face {
  font-family: "Champagn & Limousines";
  src: url("./fonts/Champagne\ &\ Limousines.ttf") format("truetype");
}
body {
  font-family: "Cangste";
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
